/* .lumber-picker-grid {
    display: grid;
    grid-gap: 15px;
    width: 100%;
    grid-template-areas: "input  output"
                         "input  output";
    grid-template-columns: 1fr 1fr;
} */

/* @media screen and (max-width: 600px) {
  .lumber-picker-grid {
      display: grid;
      grid-gap: 15px;
      width: 100%;
      grid-template-areas: "input  input"
                           "output  output";
      grid-template-rows: 1fr 1fr;
  }
} */



.lumber-picker-component-container {
  margin-bottom: 25px;
}

.lumber-picker-cell {
  -webkit-box-shadow: 3px 3px 5px 6px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    3px 3px 5px 6px #ccc;  /* Firefox 3.5 - 3.6 */
  box-shadow:         3px 3px 5px 6px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  padding: 15px;
  display: grid;
  grid-gap: 15px;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 20px 1fr 40px;
  grid-template-areas: "nav  nav"
                       "content1  content2"
                       "controls controls";

  .nav {
    grid-area: nav;
  }

  .content1 {
    grid-area: content1;
  }

  .content2 {
    grid-area: content2;
  }

  .controls {
    grid-area: controls;
    border-top: solid;
    border-top: solid;
    border-width: thin;
    border-color: grey;
    padding: 10px;
    Button {
      float: right;
    }
    .alert-slot {
      color: red;
    }
  }
}

 @media screen and (max-width: 700px) {
   .lumber-picker-cell {
     -webkit-box-shadow: 3px 3px 5px 6px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
     -moz-box-shadow:    3px 3px 5px 6px #ccc;  /* Firefox 3.5 - 3.6 */
     box-shadow:         3px 3px 5px 6px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
     padding: 15px;
     display: grid;
     grid-gap: 15px;
     width: 100%;
     grid-template-rows: 20px fit-content(100px) fit-content(100px) 60px;
     grid-template-columns: 1fr;
     grid-template-areas: "nav"
                          "content1"
                          "content2"
                          "controls";

     .nav {
       grid-area: nav;
     }

     .content1 {
       grid-area: content1;
     }

     .content2 {
       grid-area: content2;
     }

     .controls {
       grid-area: controls;
       border-top: solid;
       border-top: solid;
       border-width: thin;
       border-color: grey;
       padding: 15px;
       Button {
         float: right;
       }
       .alert-slot {
         color: red;
       }
     }
   }
}
