.back-forward-container {
  .arrow {
    color: #007bff;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
  .arrow-disabled {
    @extend .arrow;
    color: grey;
    cursor: none;
  }
}
