
@mixin app-container {
  display: grid;
  width: 100%;
  height: 100vh;
  grid-template-areas: "head head"
                       "nav  main"
                       "nav  main";
  grid-template-rows: 55px 1fr 30px;

  .logo {
    margin-left: 15px;
    padding: 5px;
    .title {
      margin-left: 7px;
      color: #fff;
    }
  }
}

.app-container-expanded-menu {
  @include app-container;
  grid-template-columns: 150px 1fr;
}

.app-container-collapsed-menu {
  @include app-container;
  grid-template-columns: 0px 1fr;
}

.header-container {
  grid-area: head;
  background-color: #293a48;
  padding-top: 15px;
  padding-left: 10px;
  color: white;
}

.nav-container {
  grid-area: nav;
  border-top: solid;
  border-width: thin;
  border-color: #fff;
  background-color: #293a48;
  /* background-color: #58668b; */
}

.nav-container .nav-link {
  color: white;
  margin: 5px;
}

.nav-container .active-nav-link {
  text-decoration: underline;
  color: white;
  margin: 5px;
}

.main-container {
  grid-area: main;
  padding: 20px;
  overflow-y: scroll;
}
